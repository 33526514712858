.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: WhiteSmoke;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(8,47,116);
}

.App-link {
  color: #61dafb;
}

body {
  background-color: Gainsboro;
  color: rgb(8,47,116);
}

p.info {
  color: black;
}
p.warning {
  color: red;
}

input {
  height: 20px;
  width: 300px;
  margin: 10px;
}
button {
  height: 40px;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: white;
  color: rgb(8,47,116);
  border: 2px solid black;
}

button:hover {
  background-color: rgb(8,47,116);
  color: white;
  cursor: pointer;
}
